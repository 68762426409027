export default {
  translation: {
    language: 'Italiano',

    navigation: {
      home: `Home`,
      telegram_group: `Gruppo Telegram`,
      whitepaper: `Whitepaper`,
    },

    banner: {
      quote: {
        text: `“Il Santo Graal di Bitcoin da quando esiste Bitcoin”`,
        author: `Vin Armani, CTO di CoinText`,
      },
      title: `Sii la tua banca con una semplice carta <strong>be.cash</strong>`,
      subtext: `
        Mantieni il pieno controllo delle tue criptovalute pur essendo in grado di spenderle con una carta.
        Ha la forma di una carta di credito, ma ha la stessa natura decentralizzata, non-custodial
        e trustless che tutti amiamo di Bitcoin.
      `,
    },

    subscribe: {
      action: `Iscriviti`,
      title: `<strong>Lascia il tuo indirizzo email</strong> per ricevere i prossimi aggiornamenti.`,
      enter_email: `Inserisci la tua email`,
      disclaimer: `Cliccando iscriviti accetti la nostra <1>privacy policy</1>`,
      announcement: [
        `be.cash annuncerà presto una campagna di crowdfunding`,
        `iscriviti per essere uno dei primi ad ottenere la carta!`,
      ],
    },

    app: {
      subtitle: `Accompagnata da un elegante portafoglio`,
      title: `<strong>Associa il tuo smartphone</strong> con la tua carta`,
      text: `
        Associa il tuo smartphone con la carta tramite un solo tocco.
        Visualizza tutte le tue transazioni in un portafoglio semplice da usare.
        Disponibile a breve, assicurati di iscriverti per ricevere gli aggiornamenti!
      `,
      subtext: `
        Paga con il tuo smartphone o con la tua carta, come preferisci.
        <br/>
        Non è necessario registrarsi, basta installarlo ed il gioco è fatto.
      `,
      watch: `Guarda una demo del portafoglio`,
    },

    bigquote: {
      text: `
        Adoro ciò che Tobias sta creando con be.cash. È il tipo di
        cosa di cui abbiamo bisogno per portare le criptovalute alle masse. Non vedo l'ora di provare
        una di queste carte!
      `,
      author: `Naomi Brookwell`,
      position: `Giornalista di Tecnologia`,
    },

    features: {
      subtitle: `Anche JK Rowling avrebbe capito come usare be.cash`,
      title: `
        <strong>Pagamenti semplici e veloci.</strong>
        <br/>
        Di cos'altro potresti aver bisogno?
      `,
      card: {
        title: `Pagamenti con carta`,
        features: [
          `Comodo Tap-To-Pay`,
          `Veloce`,
          `Non hai bisogno dello smartphone`,
          `Non è necessario avere un conto bancario o compilare un KYC`,
        ],
        text: `
          Con be.cash puoi pagare con criptovalute usando una carta, proprio come fanno tutti con le normali carte di credito.
          Non c'è bisogno di compilare nessun KYC, basta acquistare una carta, ricaricarla con criptovalute e sei pronto ad utilizzarla.
        `,
        watch: `Guarda la demo di Londra`,
      },
      nfc: {
        title: `Pagamenti NFC`,
        features: [
          `Comodo Tap-To-Pay`,
          `Veloce`,
          `Non serve una connessione internet`,
          `Non è necessario avere un conto bancario o compilare un KYC`,
        ],
        text: `
          I pagamenti NFC consentono i pagamenti con il tuo smartphone, senza dover inquadrare un codice QR 
          e anche senza avere una connessione internet.
        `,
        watch: `Guarda la demo di un pagamento NFC`,
      },
    },

    quotes: {
      title: `Citazioni`,
      subtitle: `<strong>Cosa pensano le persone</strong> di be.cash`,
      people: {
        'RoscoKalis': {
          quote: `Questo è il futuro dei pagamenti con criptovalute. Quando riusciamo a raggiungere l'usabilità
                  delle soluzioni centralizzate attuali, è in quel momento che le criptovalute prosperano davvero.
                  Tobias è un vero mago!`,
          designation: 'Autore di CashScript',
        },
        'VinArmani': {
          quote: `Questo è il più grande sviluppo negli ultimi 5 anni di Bitcoin!
                  È stato il Santo Graal di Bitcoin da quando esiste Bitcoin.
                  Un portafoglio REALE, ricaricabile e offline della stessa forma di una carta di credito.`,
          designation: 'CTO di CoinText',
        },
        'JasonBCox': {
          quote: `E' davvero fantastico. Sto aspettando questo momento da quando ho sentito parlare
                  di Bitcoin anni fa. Non vedo l'ora di provarla.`,
          designation: 'Sviluppatore di Bitcoin ABC',
        },
        'JohnNieri': {
          quote: `Questo è molto importante. Transazioni istantanee, offline e trustless.`,
          designation: 'Presidente di General Protocols',
        },
        'TylerSmith': {
          quote: `Sarebbe interessante combinare il protocollo be.cash con i normali
                  portafogli online per consentire una modalità “principalmente online” che funziona
                  senza problemi anche quando la connessione internet non è presente.`,
          designation: 'Ingegnere di AVA Labs',
        },
        'JustinBons': {
          quote: `Questi sono pagamenti offline sulla blockchain, in cui l'utente controlla ancora la propria chiave privata.
                  La facilità d'uso imita i metodi di pagamento a cui la gente è già abituata oggi.
                  Brillante svolta, proprio come l'invenzione del Bitcoin, che unisce le tecnologie esistenti.`,
          designation: 'CIO di CyberCapital',
        },
        'AmaurySechet': {
          quote: `L'integrazione delle criptovalute con i metodi di pagamento a cui le persone sono già abituate è la chiave
                  per l'adozione di massa, ed è esattamente ciò che sta facendo be.cash.`,
          designation: 'Sviluppatore principale di Bitcoin ABC',
        },
      },
    },

    footer: {
      copyright: `Copyright be.cash UG (haftungsbeschränkt) | Tutti i diritti riservati 2020`,
      privacy: `Privacy`,
      links: `Links`,
    },
  
    gdpr: {
      message:`Questo sito web utilizza i cookie per analizzare il nostro traffico. Se prosegui nella navigazione del nostro sito acconsenti all’utilizzo dei cookie`,
      necessary:`Necessari`,
      preferences:`Preferenze`,
      privacyPolicy:`Privacy Policy`,
      accept:`Accetta`,
    },
  },
};
