export default {
  translation: {
    language: 'русский',

    navigation: {
      home: `Домашняя страница`,
      telegram_group: `Группа в Телеграмме`,
      whitepaper: `О проекте`,
    },

    banner: {
      quote: {
        text: `“Этот проект словно Святой Грааль Биткойна за всё время существования Биткоина”`,
        author: `Вин Армани, технический директор CoinText`,
      },
      title: `Ты сам себе банк с картой <strong>be.cash</strong>`,
      subtext: `
         Польностью контролируй свою криптовалюту, но в то же время позволяй себе тратить ее, расплачиваясь картой.
         Такой же формы, как и кредитная карта, но с такими характеристиками как децентрализация и неограниченная свобода,
         которые мы так любим в Биткоине.
      `,
    },

    subscribe: {
      action: `Subscribe`,
      title: `<strong>Оставьте Ваш электронный</strong> адрес для обновлений`,
      enter_email: `Введите Ваш электронный адрес`,
      disclaimer: `Нажимая здесь, Вы соглашаетесь с нашей <1>политикой конфиденциальности</1>`,
      announcement: [
        `be.cash собирается объявить краудфандинговую компанию в ближайшее время`,
        `подпишитесь, чтобы первыми получить карту!`,
      ],
    },

    app: {
      subtitle: `Поддержка удобного кошелька`,
      title: `<strong>Подключите Вашу карту</strong> к телефону`,
      text: `
        Подключите карту к Вашему телефону одним нажатием.
        Просмотр всех Ваших транзакций в одном удобном кошельке.
        Доступна в ближайшее время, не забудьте подписаться на получение обновлений!
      `,
      subtext: `
        Расплачивайтесь при помощи телефона или карты - как Вам удобно.
        <br/>
        Не требуется регистрация, просто установите и пользуйтесь.`,
      watch: `Ознакомиться с демо кошельком`,
    },

    bigquote: {
      text: `
        Мне нравится то, что Тобиас создает с помощью be.cash.
        Это то, что нам нужно, чтобы сделать криптовалюту доступной всем.
        Не могу дождаться, чтобы проверить одну из этих карт!
      `,
      author: `Наоми Бруквелл`,
      position: `Технический журналист`,
    },

    features: {
      subtitle: `Даже Дж.К. Роулинг поймет, как пользоваться be.cash`,
      title: `
        <strong>Легкие и быстрые платежи</strong>.
        <br/>
        Что еще Вам понадобится?
      `,
      card: {
        title: `Платежи картой`,
        features: [
          `Удобное касание для оплаты`,
          `Быстро`,
          `Без помощи телефона`,
          `Без функции "распознавания клиента" и без банка`,
        ],
        text: `
          С be.cash Вы можете расплачиваться вашей криптовалютой так же, как обычной кредитной картой.
          Без функции "распознавания клиента". Вы просто приобретаете карту, пополняете её криптовалютой и пользуйтесь
        `,
        watch: `Ознакомиться с Лондонским демо`,
      },
      nfc: {
        title: `Платежи NFC`,
        features: [
          `Удобное касание для оплаты`,
          `Быстро`,
          `В офлайн режиме`,
          `Без функции "распознавания клиента" и без банка`,
        ],
        text: `
          Платежи NFC делают возможными операции с вашего телефона,
          без необходимости сканировать QR-код или даже без интернета.
        `,
        watch: `Ознакомиться с NFC платежами`,
      },
    },

    quotes: {
      title: `Цитаты`,
      subtitle: `<strong>Что люди думают</strong> про be.cash`,
      people: {
        'RoscoKalis': {
          name: 'Роско Калис',
          quote: `
            Это будущее криптовалютных платежей. Криптовалюта действительно может прогрессировать тогда,
            когда мы объединяем пользовательский опыт существующих централизованных решений.
            Тобиас Рук - настоящий волшебник!
          `,
          designation: 'Автор CashScript',
        },
        'VinArmani': {
          name: 'Вин Армани',
          quote: `
            Это прорыв в сфере Биткоина за последние лет 5!
            Этот проект словно Святой Грааль Биткойна за всё время существования Биткоина.
            НАСТОЯЩИЙ, многоразовый, офлайн кошелек в формате кредитной карты.
          `,
          designation: 'Главный технический директор CoinText',
        },
        'JasonBCox': {
          name: 'Джейсон Би Кокс',
          quote: `
            Это нереально круто. Я ждал этого момента с тех пор, как впервые услышал про Биткоин много лет назад.
            Не могу дождаться, чтобы попробовать самому.
          `,
          designation: 'Разработчик в Bitcoin ABC',
        },
        'JohnNieri': {
          name: 'Джон Ниери',
          quote: `Это реально событие - мгновенные оффлайн-транзакции`,
          designation: 'Президент компании General Protocols',
        },
        'TylerSmith': {
          name: 'Тайлер Смит',
          quote: `
            Протокол be.cash может быть интересен в сочетании с обычными онлайн-кошельками,
            которые «в основном онлайн», но при этом позволяя
            по-прежнему функционировать идеально с нестабильным подключением.
          `,
          designation: 'Разроботчик в компании AVA Labs',
        },
        'JustinBons': {
          name: 'Джастин Бонс',
          quote: `
            Это автономные платежи через цепочку, где пользователь также контролирует свои личные ключи.
            С пользовательским опытом, который имитирует платежи, к которым люди уже привыкли сегодня.
            Блестящий прорыв, как и изобретение Биткойна, объединяя существующие технологии
          `,
          designation: 'Директор по информационным технологиям компании',
        },
        'AmaurySechet': {
          name: 'Омэри Сэшей',
          quote: `Интеграция криптовалюты в форм-факторы, к которым привыкли люди, является ключом к массовому внедрению, и это именно то, что делает be.cash`,
          designation: `Ведущий разработчик в компании Bitcoin ABC`,
        },
      },
    },

    footer: {
      copyright: `Авторские права: Авторские права be.cash UG (haftungsbeschränkt) | Все права защищены 2020`,
      privacy: `Приватность`,
      links: `Ссылки`,
    },
  
    gdpr: {
      message: "Этот сайт использует файлы cookie для анализа нашего трафика. Вы соглашаетесь с нашими файлами cookie, продолжая использовать сайт",
      necessary: "Важное",
      preferences: "Предпочтения",
      privacyPolicy: "Политика конфиденциальности",
      accept: "Принять",
    },
  }
};
