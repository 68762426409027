export default {
  translation: {
    language: 'Deutsch',

    navigation: {
      home: `Home`,
      telegram_group: `Telegram Gruppe`,
      whitepaper: `Whitepaper`,
    },

    banner: {
      quote: {
        text: `Seit Bitcoins Existenz ist das der Heiliger Grahl des Bitcoin`,
        author: `Vin Armani, CoinText CTO`,
      },
      title: `Sei deine eigene Bank, ganz einfach mit der <strong>be.cash</strong> Karte`,
      subtext: `
        Behalte volle Kontrolle über deine Kryptos, und zahle trotzdem mit Karte.
        Nutze sie auf gleiche Weise wie eine Kreditkarte, aber mit der selben dezentralen,
        vormundschaftslosen und treuhandlosen Natur wie wir sie alle von Bitcoin
        lieben.
      `,
    },

    subscribe: {
      action: `Abbonnieren`,
      title: `<strong>Registriere deine E-Mail</strong> und bleibe auf dem neuesten Stand.`,
      enter_email: `Gib deine E-Mail ein`,
      disclaimer: `Durch das Abbonement stimmst du der <1>Datenschutzerklärung</1> zu`,
      announcement: [
        `be.cash wird bald eine Crowdfunding-Kampange ankündigen`,
        `abbonniere den Newsletter und sei der erste, der eine Karte bekommt!`,
      ],
    },

    app: {
      subtitle: `Kommt auch mit einer hippen Wallet-App`,
      title: `<strong>Koppel dein Handy</strong> mit deiner Karte`,
      text: `
        Koppel dein Handy mit deiner Karte mit nur einer Berührung.
        Zeig dir all deine Transaktionen in einer einfach zu bedienenden Wallet an.
        Schon bald verfügbar! Also stelle sicher, dass du registriert bist, um Updates zu erhalten!
      `,
      subtext: `
        Zahle mit deinem Handy oder mit deiner Karte, ganz wie du magst.
        <br/>
        Keine Anmeldung nötig, einfach nur installieren und los gehts.
      `,
      watch: `Wallet Demo abspielen`,
    },

    bigquote: {
      text: `
        Ich liebe was Tobias mit be.cash erschafft. So was brauchen 
        wir um Kryptos an die Massen zu bringen. Ich kann es kaum erwarten
        eine von diesen Karten zu testen!
      `,
      author: `Naomi Brokwell`,
      position: `Tech Journalist`,
    },

    features: {
      subtitle: `Sogar JK Rowling würde be.cash benutzen können`,
      title: `
        <strong>Einfache und schnelle Zahlungen.</strong>
        <br/>
        Was braucht man da noch?
      `,
      card: {
        title: `Kartenzahlungen`,
        features: [
          `Bequemes Tap-To-Pay`,
          `Schnell`,
          `Kein Handy nötig`,
          `Keine Identifikationsangaben nötig`,
        ],
        text: `
          Mit be.cash kannst du mit Kryptos per Karte bezahlen, genau so wie man es von 
          Kreditkarten oder Debitkarten gewohnt ist.
          Keine Identifikationsangaben nötig, du kaufst einfach eine Karte, lädst deine Kryptos drauf
          und kannst mit dem Bezahlen loslegen.
        `,
        watch: `London Demo abspielen`,
      },
      nfc: {
        title: `NFC-Zahlungen`,
        features: [
          `Bequemes Tap-To-Pay`,
          `Schnell`,
          `Kein Internet nötig`,
          `Keine Identifikationsangaben nötig`
        ],
        text: `
          NFC-Zahlungen machen Zahlungen mit deinem Handy möglich, ohne einen QR code zu scannen
          und sogar ohne Internetverbindung.
        `,
        watch: `NFC Demo abspielen`,
      },
    },

    quotes: {
      title: `Zitate`,
      subtitle: `<strong>Was andere über</strong> be.cash denken`,
      people: {
        'RoscoKalis': {
          quote: `Das ist die Zukunft von Kryptozahlungen. Wenn wir die Bedienfreundlichkeit
                  von existierenden zentralisierten Lösungen erreichen, dann werden Kryptos
                  wirklich aufblühen. Tobias Ruck ist ein wahrer Zauberer!`,
          designation: 'CashScript Autor',
        },
        'VinArmani': {
          quote: `Das ist die größte Entwicklung in den letzten 5 Jahren von Bitcoin!
                  Seit Bitcoins Existenz ist das Bitcoins Heiliger Grahl.
                  Eine ECHTE, wiederauffüllbare, offline Wallet, im gleichen Format einer Kreditkarte.`,
          designation: 'CoinText CTO',
        },
        'JasonBCox': {
          quote: `Das ist echt der Hammer. Darauf hab ich schon seit ich vor Jahren
                  das erste mal von Bitcoin gehört habe gewartet. Ich kann es kaum erwarten es
                  selbst auzuprobieren.`,
          designation: 'Bitcoin ABC Entwickler',
        },
        'JohnNieri': {
          quote: `Das ist eine große Sache. Unmittelbare offline und treuhandlose Transaktionen.`,
          designation: 'General Protocols Präsident',
        },
        'TylerSmith': {
          quote: `Es wäre interessant das be.cash Protokol mit normalen online Wallets zu kombinieren,
                  um einen „meistens online“ Modus zu ermöglichen, der trotz schlechter Verbindung
                  problemlos funktioniert.`,
          designation: 'AVA Labs Ingenieur',
        },
        'JustinBons': {
          quote: `Hier handelt es sich um Offline-Zahlungen auf der Blockchain,
                  bei denen der Nutzer dennoch seine eigenen privaten Schlüssel kontrolliert.
                  Die Bedienfreundlichkeit imitiert bereits existierende
                  Zahlungsmethoden. Brillianter Durchbruch, genau wie die Erfindung von Bitcoin,
                  eine Kombination aus existierenden Technologien.`,
          designation: 'CyberCapital CIO',
        },
        'AmaurySéchet': {
          quote: `Kryptos in die Zahlungmethoden, die die Leute bereits kennen, zu integrieren ist essenziell
                  für eine Verwendung der breiten Masse, und genau das ist es, was be.cash ermöglicht.`,
          designation: 'Bitcoin ABC Lead Developer',
        },
      },
    },

    footer: {
      copyright: `Copyright be.cash UG (haftungsbeschränkt) | All Right Reserved 2020`,
      privacy: `Datenschutz`,
      links: `Links`,
    },

    gdpr: {
      message: `
        Diese Website verwendet Cookies um unseren Traffic zu analysieren. 
        Wenn du weiterhin die Website verwendest, stimmst unseren Cookies zu.
      `,
      necessary: `Notwendige`,
      preferences: `Präferenzen`,
      privacyPolicy: `Datenschutzerklärung`,
      accept: `Akzeptieren`,
    },
  },
};
