import en from './en'
import de from './de'
import es from './es'
import pt from './pt'
import it from './it'
import ru from './ru'
import cn from './cn'
import pl from './pl'

export default {
  en,
  cn,
  de,
  es,
  pt,
  it,
  ru,
  pl,
};
