export default {
  translation: {
    language: 'English',

    navigation: {
      home: `Home`,
      telegram_group: `Telegram Group`,
      whitepaper: `Whitepaper`,
    },

    banner: {
      quote: {
        text: `“The Holy Grail of Bitcoin for as long as Bitcoin has existed”`,
        author: `Vin Armani, CTO of CoinText`,
      },
      title: `Be your own bank with a simple <strong>be.cash</strong> card`,
      subtext: `
        Keep full controll of your crypto while still being able to spend them with a card.
        Same form-factor as a credit card, but with the same decentralized, non-custodial
        and trustless nature we all love from Bitcoin.
      `,
    },

    subscribe: {
      action: `Subscribe`,
      title: `<strong>Drop your mail address </strong> for the next update.`,
      enter_email: `Enter your email`,
      disclaimer: `By clicking subscribe you agree to our <1>privacy policy</1>`,
      announcement: [
        `be.cash is going to announce a crowdfunding campaign soon`,
        `subscribe to be first to get a card!`,
      ],
      contact_title: `Get updated when <strong>stores accepting be.cash are available near you!</strong>`,
      first_name: `First name`,
      last_name: `Last name`,
      country: `Country`,
      zip_code: `ZIP Code`,
      name: `Full Name`,
      email: `Email`,
      location: `Location`,
    },

    app: {
      subtitle: `Accompanied by a slick wallet`,
      title: `<strong>Pair your phone</strong> with your card`,
      text: `
        Pair your phone with your card with one tap.
        View all your transactions in one easy to use wallet.
        Available soon, make sure to be subscribed to receive updates!
      `,
      subtext: `
        Pay with your phone or your card, as you like.
        <br/>
        No signup required, just install and go.
      `,
      watch: `Watch Wallet Demo`,
    },

    bigquote: {
      text: `
        I love what Tobias is creating with be.cash. It’s the kind of
        thing we need to bring crypto to the masses. Can’t wait to test
        one of these cards!
      `,
      author: `Naomi Brookwell`,
      position: `Tech Journalist`,
    },

    features: {
      subtitle: `Even JK Rowling would understand how to use be.cash`,
      title: `
        <strong>Simple and fast payments.</strong>
        <br/>
        What else could you need?
      `,
      card: {
        title: `Card Payments`,
        features: [
          `Convenient Tap-To-Pay`,
          `Fast`,
          `No phone needed`,
          `No KYC/bank needed`,
        ],
        text: `
          With be.cash, you can pay crypto with a card, just like people are used to with credit cards.
          No KYC required, you just buy a card, fill it up with crypto and you can spend away.
        `,
        watch: `Watch London Demo`,
      },
      nfc: {
        title: `NFC Payments`,
        features: [
          `Convenient Tap-To-Pay`,
          `Fast`,
          `No internet needed`,
          `No KYC/bank needed`,
        ],
        text: `
          NFC payments make payments with your phone possible, without having to scan a QR code,
          or without even having internet.
        `,
        watch: `Watch NFC Demo`,
      },
    },

    quotes: {
      title: `Quotes`,
      subtitle: `<strong>What people think</strong> about be.cash`,
      people: {
        'RoscoKalis': {
          quote: `This is the future of cryptocurrency payments. When we can match the UX
                  of existing centralised solutions, that is when crypto can really thrive.
                  Tobias Ruck is a genuine wizard!`,
          designation: 'Author of CashScript',
        },
        'VinArmani': {
          quote: `This is the biggest development in the last 5 years of Bitcoin!
                  It's been the holy Grail of Bitcoin for as long as Bitcoin has existed.
                  A REAL, refillable, offline wallet in the form factor of a credit card.`,
          designation: 'CTO of CoinText',
        },
        'JasonBCox': {
          quote: `This is really awesome. I've been waiting for this since I first heard
                  about Bitcoin years ago. Can't wait to try it out myself.`,
          designation: 'Developer at Bitcoin ABC',
        },
        'JohnNieri': {
          quote: `This is a big deal. Instant offline trustless transactions.`,
          designation: 'President of General Protocols',
        },
        'TylerSmith': {
          quote: `The be.cash protocol might be interesting to combine with regular
                  online wallets to enable them to be “mostly online” and still function
                  smoothly with spotty connectivity.`,
          designation: 'Engineer at AVA Labs',
        },
        'JustinBons': {
          quote: `These are offline onchain payments, where the user also controls their own private keys.
                  With a user experience that mimics payments that people are already used to today.
                  Brilliant break through, just like the invention of Bitcoin, putting existing technologies together.`,
          designation: 'CIO of CyberCapital',
        },
        'AmaurySechet': {
          quote: `Integrating crypto into form factors people are used to is key for mass adoption, and this is exactly what be.cash is doing`,
          designation: 'Lead Developer of Bitcoin ABC',
        },
      },
    },

    footer: {
      copyright: `Copyright be.cash UG (haftungsbeschränkt) | All Right Reserved 2020`,
      privacy: `Privacy`,
      links: `Links`,
    },

    gdpr: {
      message: `This website uses cookies to analyze our traffic. You consent to our cookies by continuing to use our website.`,
      necessary: `Necessary`,
      preferences: `Preferences`,
      privacyPolicy: `Privacy Policy`,
      accept: `Accept`,
    },
  },
};
