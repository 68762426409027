export default {
  translation: {
    language: 'Português',

    navigation: {
      home: `Início`,
      telegram_group: `Grupo no Telegram`,
      whitepaper: `Whitepaper`,
    },

    banner: {
      quote: {
        text: `“O Santo Graal do Bitcoin desde que existe Bitcoin”`,
        author: `Vin Armani, CTO da CoinText`,
      },
      //title: `És o teu próprio banco com um simples cartão <strong>be.cash</strong>`,
      title: `Seja o teu próprio banco com um simples cartão de <strong>be.cash</strong>`,
      subtext: `
        Mantém controlo completo do teu cripto ao mesmo tempo que fazes despesas com o teu cartão.
        As mesmas dimensões que um cartão de crédito mas a mesma natureza descentralizada, de custodia
		    própria e sem intermediários que todos adoramos no Bitcoin.
      `,
    },

    subscribe: {
      action: `Subscreve`,
      title: `<strong>Deixa o teu endereço de correio</strong> para a receberes a próxima actualização.`,
      enter_email: `Introduz o teu correio`,
      disclaimer: `Ao clicares em subscrever concordas com a nossa <1>política de privacidade</1>`,
      announcement: [
        `be.cash vai anunciar uma campanha de financiamento comunitário em breve`,
        `subscreve para seres o primeiro a receber um cartão!`,
      ],
    },

    app: {
      subtitle: `Acompanhado por uma carteira cheia de estilo`,
      title: `<strong>Emparelha o teu telefone</strong> com o teu cartão`,
      text: `
		    Emparelha o teu telefone com o teu cartão com um único toque.
        Vê todas as tuas transações numa carteira simples de usar.
		    Disponível em breve; garante que estás subscrito para receberes atualizações!
      `,
      subtext: `
		    Paga com o teu telefone ou o teu cartão, a teu belo prazer.
        <br/>
		    Não é necessário qualquer registo, simplesmente instala e usa.
      `,
      watch: `Vê um demonstração da carteira`,
    },

    bigquote: {
      text: `
		    Adoro o que o Tobias está a criar com be.cash. É o tipo de coisa que precisamos
		    para levar o cripto às massas. Mal posso esperar para testar um destes cartões!
      `,
      author: `Naomi Brookwell`,
      position: `Jornalista de tecnología`,
    },

    features: {
      subtitle: `Até a JK Rowling iria perceber como utilizar be.cash`,
      title: `
        <strong>Pagamentos simples e rápidos.</strong>
        <br/>
		    Que mais podias precisar?
      `,
      card: {
        title: `Pagamentos Com Cartão`,
        features: [
          `Pagamentos num único toque`,
          `Rápido`,
          `Não precisas do telefone`,
          `Sem conta bancária ou regras KYC`,
        ],
        text: `
          Com be.cash podes pagar cripto com um cartão, tal como já fazes com os cartões de crédito.
		      Sem necessidade de regras KYC. Apenas compras o teu cartão, carregas com cripto e começas a pagar.
        `,
        watch: `Vê a demonstração em Londres`,
      },
      nfc: {
        title: `Pagamentos NFC`,
        features: [
          `Pagamentos num único toque`,
          `Rápido`,
          `Sem necessidade de internet`,
          `Sem conta bancária ou regras KYC`,
        ],
        text: `
          Os pagamentos NFC permite pagar com o teu telefone sem ter que digitalizar um código QR ou mesmo sem internet.
        `,
        watch: `Vê a demonstração NFC`,
      },
    },

    quotes: {
      title: `Citações`,
      subtitle: `<strong>O que as pessoas pensam</strong> sobre be.cash`,
      people: {
        'RoscoKalis': {
          quote: `Este é o futuro dos pagamentos com criptomoeda. Quando conseguirmos igual a experiência
		              de utilização das soluções centralizadas atuais, será aí que o cripto vai brilhar. O Tobias
		              Ruck é um verdadeiro mágico!`,
          designation: 'Autor de CashScript',
        },
        'VinArmani': {
          quote: `Este é o maior desenvolvimento dos últimos 5 anos de Bitcoin!
                  Tem sido o Santo Graal do Bitcoin desde que existe Bitcoin.
				          Uma VERDADEIRA carteira sem internet, recarregável e com o formato de um cartão de crédito.`,
          designation: 'CTO da CoinText',
        },
        'JasonBCox': {
          quote: `Isto é realmente espetacular. Tenho estado à espera disto desde que ouvi falar do Bitcoin pela 
		              primeira vez, há anos. Mal posso esperar para o experimentar.`,
          designation: 'Programador em Bitcoin ABC',
        },
        'JohnNieri': {
          quote: `Isto é muito significativo. Transações instantâneas, sem internet e sem intermediários.`,
          designation: 'Presidente da General Protocols',
        },
        'TylerSmith': {
          quote: `O protocolo be.cash pode ser muito interessante de combinar com outras carteiras ligadas à internet
		              para as permitir ser "quase sempre online" e, ao mesmo tempo, funcionar sem problemas quando a ligação falha.`,
          designation: 'Engenheiro na AVA Labs',
        },
        'JustinBons': {
          quote: `Estes são pagamentos na rede mas sem internet, onde o utilizador controla as suas próprias chaves privadas.
				          Com uma experiência de utilização que emula pagamentos que as pessoas já estão acostumadas a fazer hoje em dia.
				          É uma inovação brilhante, tal como a invenção do Bitcoin, em que se combina tecnologias existentes.`,
          designation: 'CIO da CyberCapital',
        },
        'AmaurySechet': {
          quote: `A integração de cripto em formatos que as pessoas já estão habituadas a utilizar é essencial para uma adoção em massa e isto é exatamente o que be.cash está a fazer.`,
          designation: 'Programador Principal em Bitcoin ABC',
        },
      },
    },

    footer: {
      copyright: `Direitos de cópia be.cash UG (haftungsbeschränkt) | Todos os direitos reservados 2020`,
      privacy: `Privacidade`,
      links: `Enlaces`,
    },
  
    gdpr: {
      message: `Este site utiliza cookies para analisar o tráfego. Ao continuares a utilizar este site estás a consentir com a nossa utilização de cookies.`,
      necessary: `Necessário`,
      preferences: `Preferences`,
      privacyPolicy: `Política de Privacidade`,
      accept: `Aceitar`,
    },
  },
};
