export default {
  translation: {
    language: 'Español',

    navigation: {
      home: `Inicio`,
      telegram_group: `Grupo de Telegram`,
      whitepaper: `Whitepaper`,
    },

    banner: {
      quote: {
        text: `“El Santo Grial de Bitcoin desde que Bitcoin ha existido”`,
        author: `Vin Armani, CTO de CoinText`,
      },
      title: `Sé tu propio banco con una sencilla tarjeta <strong>be.cash</strong>`,
      subtext: `
        Mantén el completo control de tus criptomonedas mientras conservas la capacidad de usarlas con una tarjeta.
        El mismo factor de forma de una tarjeta de crédito, pero con la misma naturaleza descentralizada, sin custodia
        y sin el requisito de confianza que todos amamos de Bitcoin.
      `,
    },

    subscribe: {
      action: `Suscribirse`,
      title: `<strong>Registra tu dirección de correo </strong> para próximas actualizaciones.`,
      enter_email: `Ingresa tu correo`,
      disclaimer: `Al presionar suscribirse aceptas nuestra <1>política de privacidad</1>`,
      announcement: [
        `be.cash anunciará pronto su campaña de crowdfunding`,
        `¡suscríbete para ser el primero en tener una tarjeta!`,
      ],
    },

    app: {
      subtitle: `Acompañada de una atractiva billetera`,
      title: `<strong>Empareja tu teléfono</strong> con tu tarjeta`,
      text: `
        Empareja tu teléfono con tu tarjeta con un solo toque.
        Visualiza todas tus transacciones en una billetera fácil de usar.
        Disponible pronto, ¡asegúrate de suscribirte para recibir las actualizaciones!
      `,
      subtext: `
        Paga con tu teléfono o tu tarjeta, como quieras.
        <br/>
        Sin necesidad de registrarte, solo instálala y listo.
      `,
      watch: `Mira el demo de la billetera`,
    },

    bigquote: {
      text: `
        Amo lo que Tobias está creando con be.cash. Es el tipo de
        cosas que necesitamos para llevarle las criptomonedas a las masas. ¡No puedo
        esperar para probar una de esas tarjetas!
      `,
      author: `Naomi Brookwell`,
      position: `Periodista de Tecnología`,
    },

    features: {
      subtitle: `Incluso JK Rowling entendería como usar be.cash`,
      title: `
        <strong>Pagos sencillos y rápidos.</strong>
        <br/>
        ¿Qué más podrías necesitar?
      `,
      card: {
        title: `Pagos con Tarjeta`,
        features: [
          `Cómodo sistema de Tocar-para-Pagar (Tap-To-Pay)`,
          `Rápidos`,
          `Sin necesidad de teléfono`,
          `Sin necesidad de KYC/bancos`,
        ],
        text: `
          Con be.cash puedes pagar con criptomonedas usando una tarjeta, justo como la gente suele hacerlo con las tarjetas de crédito.
          Sin requisitos de KYC, solo compras una tarjeta, la recargas con criptomonedas y estás listo para usarla.
        `,
        watch: `Mira el demo de Londres`,
      },
      nfc: {
        title: `Pagos con NFC`,
        features: [
          `Cómodo sistema de Tocar-para-Pagar (Tap-To-Pay)`,
          `Rápidos`,
          `Sin necesidad de internet`,
          `Sin necesidad de KYC/bancos`,
        ],
        text: `
          La tecnología NFC hace que los pagos con tu teléfono sean posibles, sin tener que escanear un código QR
          o incluso sin tener internet.
        `,
        watch: `Mira el demo de NFC`,
      },
    },

    quotes: {
      title: `Citas`,
      subtitle: `<strong>Lo que piensa la gente</strong> acerca de be.cash`,
      people: {
        'RoscoKalis': {
          quote: `Este es el futuro de los pagos con criptomonedas. Cuando podemos igualar la UX
                  de las soluciones centralizadas ya existentes, ahí es cuando las criptomonedas pueden prosperar.
                  ¡Tobias Ruck es un verdadero mago!`,
          designation: 'Autor de CashScript',
        },
        'VinArmani': {
          quote: `¡Este es el desarrollo más grande en los últimos 5 años de Bitcoin!
                  Ha sido el Santo Grial de Bitcoin desde que éste ha existido.
                  Una VERDADERA billetera offline y recargable con el factor de forma de una tarjeta de crédito.`,
          designation: 'CTO de CoinText',
        },
        'JasonBCox': {
          quote: `Es realmente sorprendente. He estado esperando por esto desde que escuché de Bitcoin
                  por primera vez hace varios años. No puedo esperar a probarla yo mimso.`,
          designation: 'Desarrollador de Bitcoin ABC',
        },
        'JohnNieri': {
          quote: `Esto es importante. Transacciones offline, instantáneas y que no requieren de confianza.`,
          designation: 'Presidente de General Protocols',
        },
        'TylerSmith': {
          quote: `Puede ser interesante combinar el protocolo de be.cash con las billeteras online
                  normales para permitirles funcionar “principalmente online”, pero continuar funcioanndo
                  sin problemas incluso cuando falla la conexión.`,
          designation: 'Ingeniero de AVA Labs',
        },
        'JustinBons': {
          quote: `Son pagos offline directamente en la blockchain, donde además el usuario controla sus propias llaves privadas.
                  Con una experiencia del usuario que imita los pagos a los que la gente ya está acostumbrada actualmente.
                  Un avance brillante, justo como la invención del Bitcoin, combinando tecnologías ya existentes.`,
          designation: 'CIO de CyberCapital',
        },
        'AmaurySechet': {
          quote: `Integrar las criptomonedas en un elemento al que gente ya está acostumbrada es clave para la adopción
                  masiva, y esto es exactamente lo que está haciendo be.cash`,
          designation: 'Desarrollador principal de Bitcoin ABC',
        },
      },
    },

    footer: {
      copyright: `Copyright be.cash UG (haftungsbeschränkt) | Todos los derechos reservados 2020`,
      privacy: `Privacidad`,
      links: `Enlaces`,
    },

    gdpr: {
      message: `This website uses cookies to analyze our traffic. You consent to our cookies by continuing to use our website.`,
      necessary: `Necessary`,
      preferences: `Preferences`,
      privacyPolicy: `Privacy Policy`,
      accept: `Accept`,
    },
  },
};
