export default {
  translation: {
    language: '中文',

    navigation: {
      home: `首頁`,
      telegram_group: `电报组`,
      whitepaper: `白皮书`,
    },

    banner: {
      quote: {
        text: `“只要比特币存在，比特币的圣杯”`,
        author: `Vin Armani, CoinText首席技术官`,
      },
      title: `使用简单的<strong>be.cash</strong>卡，成为自己的银行`,
      subtext: `
        完整掌控您的虛擬貨幣，同时仍可以透過卡片使用它们。be.cash与信用卡具有相同的外形尺寸
        但是它具有和比特幣同樣吸引人的性質：去中心化，無須託管和去信任化。
      `,
    },

    subscribe: {
      action: `订阅`,
      title: `留下您的電子邮件地址以獲得最新資訊。`,
      enter_email: `输入你的电子邮箱`,
      disclaimer: `<1>点击订阅即表示您同意我们的隐私政策</1>`,
      announcement: [
        `be.cash即将宣布一项群眾募資活动`,
        `订阅並成为第一位获得be.cash卡！`,
      ],
    },

    app: {
      subtitle: `搭配功能完善的钱包`,
      title: `将您的手机与您的卡配对`,
      text: `
        一键将您的手机与您的卡配对。
        在一个易于使用的钱包中查看您所有的交易。
        即将推出，请确保已订阅以接收資訊更新！      
      `,
      subtext: `
        您可以根据您的需求，使用手机或卡片付款。
        <br/>
        无須註冊，只需安装即可。
      `,
      watch: `观看电子钱包演示`,
    },

    bigquote: {
      text: `
        我喜欢Tobias用be.cash创造的东西，这是我们将虛擬貨幣带给大众時所需要的。等不及要测试be.cash卡了
      `,
      author: `Naomi Brookwell`,
      position: `技术记者`,
    },

    features: {
      subtitle: `甚至JK罗琳（JK Rowling）都知道如何使用be.cash卡`,
      title: `
        简单快捷的付款方式。
        <br/>
        您还需要什么？
      `,
      card: {
        title: `卡片付款`,
        features: [
          `便捷的点击付款`,
          `快速`,
          `不需要电话`,
          `无需KYC /银行`,
        ],
        text: `
          使用be.cash卡，您可以支付虛擬貨幣，就像人们所习惯使用的信用卡一样。
          无需KYC，您只須购买一张卡，用虛擬貨幣儲值後，即可使用。
        `,
        watch: `观看伦敦展示`,
      },
      nfc: {
        title: `NFC付款`,
        features: [
          `便捷的点击付款`,
          `快速`,
          `无須網路`,
          `无須KYC /银行`,
        ],
        text: `
          通过NFC付款，无須扫描QR码，甚至不需要有網路，您便可以使用手机进行付款。
        `,
        watch: `观看NFC展示`,
      },
    },

    quotes: {
      title: `引述`,
      subtitle: `人们对be.cash的看法`,
      people: {
        'RoscoKalis': {
          quote: `这是虛擬货币支付方式的未来。当我们可以配對现有的中心化
                  解决方案之使用者經驗时，就意味着虛擬貨幣可以蓬勃发展。 
                  Tobias Ruck是一位真正的魔法師！`,
          designation: 'CashScript的作者',
        },
        'VinArmani': {
          quote: `这是比特币过去5年中最大的進展！只要比特币存在，它就一直是比特币的圣杯。
                  一个真正的，可儲值的离线钱包，並且和信用卡為相同的形式！`,
          designation: 'CoinText首席技术官',
        },
        'JasonBCox': {
          quote: `真的很棒！自从几年前我第一次听说比特币以来，
                  我一直在等待這樣的東西。迫不及待想親自尝试一下。`,
          designation: 'Bitcoin ABC的开发人员',
        },
        'JohnNieri': {
          quote: `这是一个大進展。即时的离线去信任化交易。`,
          designation: 'General Protocols 总裁',
        },
        'TylerSmith': {
          quote: `将be.cash协议与一般的線上钱包结合使用可能会很有趣，
                  能夠使它们為「幾乎線上」，同時又仍可以在不順暢的網路連接平稳運作。`,
          designation: 'AVA Labs的工程师',
        },
        'JustinBons': {
          quote: `这些是离线链上支付方式，透過仿現今人們已習慣的付款方式之使用者體驗，
                  用户还可以在其中控制自己的私钥。就像比特币的发明一样，
                  卓越的突破将现有的科技融合在一起。`,
          designation: 'CyberCapital的CIO',
        },
        'AmaurySechet': {
          quote: `将虛擬貨幣與人们习惯的形式結合是使大眾接受的关键，而这正是be.cash正在做的事情`,
          designation: 'Bitcoin ABC 首席开发商',
        },
      },
    },

    footer: {
      copyright: `版权 be.cash UG (haftungsbeschränkt) | 保留所有权利2020`,
      privacy: `隐私`,
      links: `連結`,
    },

    gdpr: {
      message: `This website uses cookies to analyze our traffic. You consent to our cookies by continuing to use our website.`,
      necessary: `Necessary`,
      preferences: `Preferences`,
      privacyPolicy: `Privacy Policy`,
      accept: `Accept`,
    },
  },
};
