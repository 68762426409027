export default {
  translation: {
    language: 'Polski',

    navigation: {
      home: `Główna`,
      telegram_group: `Grupa na Telegramie`,
      whitepaper: `Whitepaper`,
    },

    banner: {
      quote: {
        text: `“Święty Graal Bitcoina od samego początku jego istnienia.”`,
        author: `Vin Armani, CTO firmy CoinText`,
      },
      title: `Bądź własnym bankiem ze zwyczajną kartą <strong>be.cash</strong>`,
      subtext: `
        Miej pełną kontrolę nad swoimi kryptowalutami jednocześnie mogąc je wydawać za pomocą zwykłej karty.
        Taki sam format, jak karty kredytowej, ale z tą samą decentralizacją i wolną od zaufanych pośredników naturą, 
        za którą wszyscy kochamy Bitcoina.
      `,
    },

    subscribe: {
      action: `Subskrybuj`,
      title: `<strong>Podaj swój adres </strong> aby dostać następną aktualizację.`,
      enter_email: `Wprowadź e-mail`,
      disclaimer: `Klikająć Subskrybuj, zgadzasz się na naszą <1>politykę prywatności</1>`,
      announcement: [
        `be.cash zamierza wkrótce ogłosić kampanię crowdfundingową`,
        `zasubskrybuj, aby być wśród pierwszych, którzy dostaną kartę!`,
      ],
    },

    app: {
      subtitle: `W zestawie schludny portfel`,
      title: `<strong>Sparuj swój telefon</strong> ze swoją kartą`,
      text: `
        Sparuj swój telefon ze swoją kartą jednym dotknięciem.
        Zobacz wszystkie transakcje w jednym, łatwym w użyciu portfelu.
        Dostępny wkrótce, nie zapomnij zasubskrybować, aby otrzymywać aktualizacje!
      `,
      subtext: `
        Płać swoim telefonem lub kartą, jak wolisz.
        <br/>
        Nie potrzeba żadnej rejestracji, po prostu zainstaluj i gotowe.
      `,
      watch: `Zobacz demo portfela`,
    },

    bigquote: {
      text: `
        Uwielbiam, co Tobias tworzy w be.cash. To taka rzecz
        jakiej potrzebujemy, aby trafić z krypto do mas. Nie mogę się doczekać,
        aby przetestować jedna z tych kart!
      `,
      author: `Naomi Brookwell`,
      position: `Tech Journalist`,
    },

    features: {
      subtitle: `Nawet JK Rowling Zrozumiałaby, jak używać be.cash`,
      title: `
        <strong>Proste i szybkie płatności.</strong>
        <br/>
        Czego więcej potrzebujesz?
      `,
      card: {
        title: `Płatności kartą`,
        features: [
          `Wygodne płatności zbliżeniowe`,
          `Szybkie`,
          `Nie potrzeba telefonu`,
          `Nie potrzeba procedur KYC/banku`,
        ],
        text: `
          Z be.cash, możesz płącić w kryptowalutach kartą, tak jak ludzie zwykle robią kartami kredytowymi.
          Nie potrzeba procedur KYC, po prostu kup kartę, doładuj ją kryptowalutą i już możesz wydawać.
        `,
        watch: `Zobacz demo w Londynie`,
      },
      nfc: {
        title: `Płatności NFC`,
        features: [
          `Wygodne płatności zbliżeniowe`,
          `Szybkie`,
          `Nie potrzeba telefonu`,
          `Nie potrzeba procedur KYC/banku`,
        ],
        text: `
          Płatności NFC umożliwiają płacenie za pomocę telefonu, bez konieczności skanowania kodu QR,
          a nawet bez internetu.
        `,
        watch: `Zobacz demo NFC`,
      },
    },

    quotes: {
      title: `Mówią o nas`,
      subtitle: `<strong>Co ludzie myślą</strong> o be.cash`,
      people: {
        'RoscoKalis': {
          quote: `To jest przyszłość płatności kryptowalutowych. Kiedy bedziemy mogli dorównać UX-owi
                  scentralizowanych rozwiązań, wtedy krypto będzie mogło naprawdę rozkwitnąć.
                  Tobias Ruck to prawdziwy czarodziej!`,
          designation: 'Autor CashScript',
        },
        'VinArmani': {
          quote: `To największe osiągniecie w ciągu sotatnich 5 lat Bitcoina!
                  To był Święty Graal Bitcoina odkąd tylko Bitcoin istnieje.
                  PRAWDZIWY, doładowywlny, portfel offline w formacie karty kredytowej.`,
          designation: 'CTO CoinText',
        },
        'JasonBCox': {
          quote: `To naprawdę niesamowite. Czekałem na to odkąd po raz pierwszy usłyszałem
                  o Bitcoinie lata temu. Nie mogę się doczekać, aby to samemu wypróbować.`,
          designation: 'Developer w Bitcoin ABC',
        },
        'JohnNieri': {
          quote: `To wielka rzecz. Natychmiastowe, offline'owe transakcje bez zaufanych pośredników.`,
          designation: 'Prezes General Protocols',
        },
        'TylerSmith': {
          quote: `Protokół be.cash może być ciekawy w połączeniu z normalnymi
                  portfelami online aby umożliwić im być “głównie online” ale wciąż sprawnie działać
                  przy słabym zasięgu sieci.`,
          designation: 'Inżynier w AVA Labs',
        },
        'JustinBons': {
          quote: `To są offline'owe płatności onchain, gdzie użytkownik również zachowuje kontrolę nad swoimi kluczami prywatnymi.
                  Z doświadczeniem użytkownika, które naśladuje płatności, do jakich ludzie są już dzisiaj przyzwyczajeni.
                  Olśniewający przełom, tak jak wynalezienie Bitcoina, składający razem istniejące technologie.`,
          designation: 'CIO CyberCapital',
        },
        'AmaurySechet': {
          quote: `Zintegrowanie krypto do formatów, do jakich ludzie są przyzwyczajeni, to klucz do masowego przyjęcia i to jest dokładnie to, co robi be.cash`,
          designation: 'Lead Developer w Bitcoin ABC',
        },
      },
    },

    footer: {
      copyright: `Copyright be.cash UG (haftungsbeschränkt) | All Right Reserved 2020`,
      privacy: `Polityka prywatności`,
      links: `Linki`,
    },
  
    gdpr: {
      message: `Ta strona używa cookies do analizy ruchu. Wyrażasz zgodę na nasze cookies przez dalsze korzystanie z naszej strony.`,
      necessary: `Wymagane`,
      preferences: `Ustawienia`,
      privacyPolicy: `Polityka prywatności`,
      accept: `Akceptuj`,
    },
  },
};
