import React from "react";
import * as reactI18next from 'react-i18next';
import countries from 'i18n-iso-countries';

const ReactTrans = reactI18next.Trans;

export const useTranslation = reactI18next.useTranslation;

countries.registerLocale(require("i18n-iso-countries/langs/de.json"));
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));
countries.registerLocale(require("i18n-iso-countries/langs/it.json"));
countries.registerLocale(require("i18n-iso-countries/langs/pl.json"));
countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));
countries.registerLocale(require("i18n-iso-countries/langs/ru.json"));

export const Trans = ({
  children,
  ...props
}) => {
  return (
    <ReactTrans 
      tOptions={{'interpolation': {'escapeValue': false}}}
      {...props}
    >
      {children}
    </ReactTrans>
  )
};
