import translations from 'common/src/assets/data/translations'

const Data = {
    menuItems: (t) => {
      const languages = [];
      for (const k in translations) {
        languages.push(k);
      }
      return [
        {
          name: t('navigation.home'),
          path: "/",
        },
        {
          name: t('navigation.telegram_group'),
          path: "https://t.me/be_cash",
          offset: "-50",
          openInNewTab: true,
        },
        {
          name: t('navigation.whitepaper'),
          path: "becash.pdf",
          offset: "-50"
        },
        {
          name: t('language'),
          subItems: languages.map(k => ({
            name: t('language', {lng: k}),
            path: "/?lng=" + k,
          }))
        },
      ]
    }
  };
  export default Data;
  